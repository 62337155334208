<!--盘点差异-->
<template>
    <div class="CheckDiff">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="80px">
                <el-row>
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                    <el-col :span="8">
                        <el-form-item label="盘点编号">
                            <el-input placeholder="请填写" v-model="form.bizCode" style="width: 215px" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                    <!--                    <el-col :span="8">-->
                    <!--                        <el-form-item label="审核状态">-->
                    <!--                            <el-select v-model="auditStatus" placeholder="请选择">-->
                    <!--                                <el-option-->
                    <!--                                    v-for="item in auditStatusOptions"-->
                    <!--                                    :key="item.value"-->
                    <!--                                    :label="item.label"-->
                    <!--                                    :value="item.value"-->
                    <!--                                >-->
                    <!--                                </el-option>-->
                    <!--                            </el-select>-->
                    <!--                        </el-form-item>-->
                    <!--                    </el-col>-->
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.stock.checkDiff.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.stock.checkDiff.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                :data="tableData"
                ref="table"
                id="printMe"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="盘点编码" width="240" prop="bizCode" />
                <el-table-column label="机构名称" width="160" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="160" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="260"
                    header-align="center"
                    prop="operate"
                    key="operate"
                    v-if="showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.stock.checkDiff.open')"
                            >查看
                        </el-button>
                        <el-button
                            type="primary"
                            size="mini"
                            @click="rowDetail(scope.row, false)"
                            :disabled="scope.row.disableDiff"
                            v-if="hasPrivilege('biz.stock.lossAndOverflow.autoAdjustStock')"
                            >一键报损报溢
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import EfPagination from 'components/EfPagination';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'CheckDiff',
    components: {
        EfEndDate,
        EfStartDate,
        EfDeptTypeAndDeptSelect,
        EfPagination,
        CheckTableShowColumnDialog,
    },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            baseUrl: this.$efApi.stockCheckDiffApi.baseUrl,
            form: {
                code: '',
                deptCode: '',
                deptType: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: 100,
            },
            total: 0,
            tableData: [],
            url: {
                page: '/stock/check/diff/page',
            },
            auditStatusOptions: [
                {
                    label: '请选择',
                    value: '',
                },
                {
                    label: '待审核',
                    value: 0,
                },
                {
                    label: '已审核',
                    value: 2,
                },
                {
                    label: '已拒绝',
                    value: -1,
                },
            ],
            auditStatus: '',
        };
    },
    mounted() {},
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
            if (deptType) {
                this.handleQuery();
            }
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.queryList();
        },
        handleQuery() {
            this.form.page = 1;
            this.queryList();
        },
        async queryList() {
            const rst = await this.$efApi.stockCheckDiffApi.page(this.form);
            this.tableData = rst.data.map((e) => {
                return {
                    ...e,
                    disableDiff: e.stockStatus === 1,
                };
            });
            this.total = rst.count;
        },
        rowDetail(row, type = true) {
            const _this = this;

            if (type) {
                Util.nameJump(this, 'menu.stock.checkDiff.detail', ['库存管理', '盘点差异管理', '盘点差异详情'], {
                    form: row,
                    code: row.code,
                });
            } else {
                UrlUtils.PostRemote(
                    _this,
                    `/stock/check/diff/autoLossAndOverflowAdjustStock/${row.code}`,
                    {
                        message: '是否确认报损/报溢商品盈亏数量，确认后将调整库存数量?',
                    },
                    null,
                    () => {
                        this.$message.success('操作成功');
                        this.handleQuery();
                    }
                );
            }
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '盘点差异管理', '/stock/check/diff/export', this.form, codes);
        },
    },
};
</script>

<style scoped>
.CheckDiff .el-form-item {
    margin-bottom: 0;
}
</style>
